





























import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaLoaderCircle, DefaTransitionFadeOutIn } from "@defa-as/components";
import type { Nullable, Retailer, UserWithPublicRGInfo } from "@defa-as/utils";
import { publicRetailerGroupHandle } from "@defa-as/utils";
import PublicRGStatusError from "@/components/public-rg/PublicRGStatusError.vue";
import PublicRGConnection from "@/components/public-rg/PublicRGConnection.vue";
import { userModule } from "@/store/modules/user";
import { getUserInfo } from "@/http/requests/requests-user";
import { getRetailers } from "@/http/requests/requests-retailer";

@Component({
  components: {
    PublicRGConnection,
    PublicRGStatusError,
    DefaLoaderCircle,
    DefaTransitionFadeOutIn,
  },
})
export default class ViewRetailerConnectionForPublicRG extends Vue {
  @Prop({ required: true }) readonly userId!: string;

  loading = false;
  success = false;

  user: Nullable<UserWithPublicRGInfo> = null;
  retailers: Retailer[] = [];

  get adminRulingExists() {
    return this.user ? userModule.adminRulingExists(this.user) : false;
  }

  get userEmail() {
    return this.user?.email;
  }

  get userEmailLink() {
    if (this.userEmail) {
      return `mailto:${this.userEmail}`;
    }
    return null;
  }

  async created() {
    this.loading = true;
    try {
      this.user = await getUserInfo<UserWithPublicRGInfo>(this.userId, true);
      this.retailers = await getRetailers(publicRetailerGroupHandle);
    } finally {
      this.loading = false;
    }
  }

  onLinkSuccess() {
    this.success = true;
  }
}
