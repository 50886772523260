import { render, staticRenderFns } from "./PublicRGSuggestedRetailers.vue?vue&type=template&id=647dc8f0&scoped=true&"
import script from "./PublicRGSuggestedRetailers.vue?vue&type=script&lang=ts&"
export * from "./PublicRGSuggestedRetailers.vue?vue&type=script&lang=ts&"
import style0 from "./PublicRGSuggestedRetailers.vue?vue&type=style&index=0&id=647dc8f0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "647dc8f0",
  null
  
)

export default component.exports