








































































import { Component, Prop, Vue } from "vue-property-decorator";
import type { Retailer } from "@defa-as/utils";

@Component
export default class SuggestedRetailersText extends Vue {
  @Prop({ required: true }) readonly suggestedRetailers!: Retailer[];
  @Prop({ required: true }) readonly submittedOrgIdMatched!: boolean;
  @Prop({ required: true }) readonly retailerNameInput!: string;
  @Prop({ required: true }) readonly retailerOrgNumberInput!: string;

  get hasNoSuggestedRetailers() {
    return this.suggestedRetailers.length === 0;
  }

  get hasSingleSuggestedRetailer() {
    return this.suggestedRetailers.length === 1;
  }

  get hasMultipleSuggestedRetailers() {
    return this.suggestedRetailers.length > 1;
  }
}
