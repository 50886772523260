













































import { Component, Prop, Vue } from "vue-property-decorator";
import { DefaBaseLinkExternal, DefaBaseTextarea } from "@defa-as/components";
import type { UserWithPublicRGInfo } from "@defa-as/utils";
import {
  captureExceptionWithSentry,
  getLocale,
  Retailer,
} from "@defa-as/utils";
import PublicRGSuggestedRetailers from "@/components/public-rg/PublicRGSuggestedRetailers.vue";
import RetailerForm from "@/components/retailer/RetailerForm.vue";
import PublicRGUserContactInfo from "@/components/public-rg/PublicRGUserContactInfo.vue";
import { linkRetailerForPublicRg } from "@/http/requests/requests-user";

@Component({
  components: {
    PublicRGUserContactInfo,
    DefaBaseLinkExternal,
    DefaBaseTextarea,
    PublicRGSuggestedRetailers,
    RetailerForm,
  },
})
export default class PublicRGConnectionText extends Vue {
  @Prop({ required: true }) readonly user!: UserWithPublicRGInfo;
  @Prop({ required: true }) readonly retailers!: Retailer[];
  loading = false;
  model = {
    comment: "",
  };

  get dataImportSheetLink() {
    switch (getLocale()) {
      case "nb":
        return "https://docs.google.com/spreadsheets/d/1_bO7SXeidH5Ow7wfKnuLOB4P8VQmMlSPHio2WxfmfY8/edit#gid=624018400";
      case "sv":
        return "https://docs.google.com/spreadsheets/d/13j4rkGCxTvdWPIaA8Txj5VwY0IlEltyOVmHvYzKrdcM/edit#gid=624018400";
      case "da":
        return "https://docs.google.com/spreadsheets/d/117rcDpyrlrufznxMOD-A5MWS187VxK1y_Gi7KohR8kQ/edit#gid=645108465";
      case "fi":
        return "https://docs.google.com/spreadsheets/d/1iIKhX6_lLlRW5ceZqCvBKcWYWhcD62sM52YV8hWCz2A/edit#gid=645108465";
      default:
        captureExceptionWithSentry(
          new Error(
            `No link for the data import sheet for locale ${getLocale()} has been configured`
          )
        );
        return null;
    }
  }

  get suggestedRetailers() {
    return this.retailers.filter((retailer) =>
      this.user.publicRg.suggestedRetailers.retailerIds.includes(retailer.id)
    );
  }

  get initialRetailerId() {
    return this.suggestedRetailers?.length === 1
      ? this.suggestedRetailers[0].id
      : "";
  }

  async onSubmit(selectedRetailerId: string) {
    this.loading = true;
    try {
      await linkRetailerForPublicRg(this.user.id, {
        adminComment: this.model.comment,
        retailerId: selectedRetailerId,
      });
      this.$emit("link-success");
    } finally {
      this.loading = false;
    }
  }
}
